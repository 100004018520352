import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Inline from "yet-another-react-lightbox/plugins/inline";
import image1 from './image1.jpeg'


import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";


export default function App() {
  const [openLightBox, setLightBoxOpen] = React.useState(false);
  const [index, setIndex] = React.useState(0);

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  }

  const updateIndex = ({ index: current }) =>
    setIndex(current);

    const videos = [
      {
        title: "Big Buck Bunny",
        type: "loom",
        width: 1920,
        height: 1080,
        src: 'https://www.loom.com/embed/5820698868a3451491755f8e37537358?sid=35119e0d-2915-45b7-a9c0-7dcfa4e64982',
      }
    ];

    const photos = [1,2,3,4].map(() => ({ src: `https://picsum.photos/seed/${getRandomInt(500)}/1920/1080`, width: '1920', height: '1080', title: "some title", description: "Some description" }))

  return (
    <>
      <Lightbox
        open={openLightBox}
        close={() => setLightBoxOpen(false)}
        index={index}
        slides={[...videos, ...photos]}
        plugins={[Captions, Fullscreen, Slideshow, Thumbnails, Video, Zoom, Inline]}
        on={{
          view: updateIndex,
          click: () => {setLightBoxOpen(true)},
        }}
        carousel={{
          padding: 0,
          spacing: 0,
          imageFit: "contain",
        }}
        inline={{
          style: {
            width: "100%",
            maxWidth: "900px",
            aspectRatio: "2 / 2",
            margin: "0 auto",
          },
        }}
        render={{
          thumbnail: ({ slide, rect, render, imageFit }) => 
            slide.type === 'loom' ? (
              <img src={image1} alt="logo" width={Math.min(
                rect.width,
              )}
              height={Math.min(
                rect.height,
              )}/> 
            ) : null,
          slide: ({ slide: { type, src, width, height, title }, rect }) =>
            type === "loom" ? (
              <iframe
                width={Math.min(
                  width,
                  rect.width,
                  (width * rect.height) / height
                )}
                height={Math.min(
                  height,
                  rect.height,
                  (height * rect.width) / width
                )}
                src={src}
                title={title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            ) : null
        }}
      />
      <img src="https://www.loom.com/embed/5820698868a3451491755f8e37537358?sid=35119e0d-2915-45b7-a9c0-7dcfa4e64982"/>
    </>
  );
}

